.messages-filter {
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .action-button {
    width: 150px;
    border: 1px solid grey;
  }

  .speakers {
    max-height: 250px;
    overflow-y: scroll;
  }

  .custom-width {
    width: 350px;
  }

  .custom-height {
    min-height: 100px;
    height: auto;
  }

  .w-full {
    width: 100%;
  }
}
