.onboarding-container {
  height: 100svh;
  overflow: hidden;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //align-items: center;
  //gap: 25px;
}

.cat-top-part {
  animation: cat-sleep-animation 2s infinite;
}

.cat-bottom-part {
  animation: cat-bottom-sleep 2s infinite;
}

@keyframes cat-bottom-sleep {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(2deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes cat-sleep-animation {
    0% {
        transform: rotate(45deg);
    }
    50% {
        transform: rotate(47deg);
    }
    100% {
        transform: rotate(45deg);
    }
}

.bottom-cat {
  margin-top: -25px;
}

.welcome {
  .path {
    stroke-width: 6;
    stroke-dasharray: 3000;
    stroke-dashoffset: 1;
    animation: dash 5s linear alternate infinite;
    animation-fill-mode: forwards;
  }

  @keyframes dash {
    from {
      stroke-dashoffset: 3000;
    }

    50% {
      fill: rgba(213, 213, 222, 0.3);
    }

    99.999% {
      stroke-dashoffset: 0;
    }
    100% {
      fill: rgba(50, 59, 64, 0.3);
    }
  }
}
