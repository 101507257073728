.login-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
  height: 100vh;
  background-color: var(--bg);
  color: #fff;
  font-family: "Inter", "DM Sans", Arial, sans-serif;

  img.cat-paw-bg {
    max-width: 250px;
    height: auto;
    position: absolute;
    left: -30px;
    top: 0;
    filter: invert(1);
  }

  .login-left-side {
    display: grid;
    place-items: center;
    gap: 0;
    background-color: #11111c;
    color: #fff;
    font-family: "Inter", "DM Sans", Arial, sans-serif;


  }
}
