.list {
  //max-width: 100%;
  max-height: calc(100% - 100px);
  overflow-y: scroll;
  overflow-x: hidden;

}
.panel-separator {
  border: 1px solid white;
  margin: 10px;
}

.transcription-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
