@import 'tailwindcss/tailwind.css';
@import 'daisyui/dist/full.css';
@import 'daisyui/dist/styled.css';

:root {
  color-scheme: only light;
}

body {
  //background-color: #11111c;
}

.text-white {
  color: white !important;
}

.f-black {
  color: black;
}

.h2-white-title {
  h2 {
    color: black;
    padding: 10px;
    background-color: white;
    border-radius: 8px;
  }
}

.mbox-h2 {
  font-weight: bold;
  font-size: 1.5rem;
}

.additional-text-color {
  color: #B5B5B5;;
}

.text-primary {
  color: #007bff;
}

.text-secondary {
  color: #6c757d;
}

.text-bold {
  font-weight: bold;
  color: black;
}


.gold {
  text-transform: uppercase;
  text-align: center;
  //background: linear-gradient(90deg, rgba(186,148,62,1) 0%, rgba(236,172,32,1) 20%, rgba(186,148,62,1) 39%, rgba(249,244,180,1) 50%, rgba(186,148,62,1) 60%, rgba(236,172,32,1) 80%, rgba(186,148,62,1) 100%);
  background: linear-gradient(90deg,
          rgb(227, 179, 78) 0%,
          rgba(236, 172, 32, 1) 20%,
          rgb(120, 136, 225) 39%,
          rgb(255, 221, 0) 50%,
          rgba(255, 180, 0) 60%,
          rgba(255, 190, 0) 80%,
          rgba(255, 221, 0) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s;
  background-size: 400%;
  background-position: left;
}

.gold-2 {
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
  background: linear-gradient(90deg, rgba(186, 148, 62, 1) 0%, rgba(236, 172, 32, 1) 20%, rgba(186, 148, 62, 1) 39%, rgba(249, 244, 180, 1) 50%, rgba(186, 148, 62, 1) 60%, rgba(236, 172, 32, 1) 80%, rgba(186, 148, 62, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite;
  background-size: 400%;
  background-position: left;
}

@keyframes shine {
  25% {
    background-position: right
  }
  100% {
    background-position: left
  }

}

/* animations.css */

/* Анимация сдвига влево */
@keyframes slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    /* регулируйте, насколько далеко элемент смещается */
    transform: translateX(-200px);
  }
}

.slide-left {
  animation: slide-left 0.7s forwards; /* 0.7s => длительность, можно менять */
}

/* Анимация плавного появления (fade-in) */
@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateX(50px); /* Дополнительно: сдвиг чуть правее, если хотите */
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fade-out {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30%);
  }
}

.fade-out {
  animation: fade-out 1s forwards;
  opacity: 1;
}

.fade-in {
  animation: fade-in 0.7s forwards; /* 0.7s => длительность, можно менять */
  opacity: 0; /* Изначально скрыт */
}


.custom-article {
  max-height: 50%;
  overflow-y: scroll;
}


.debug {
  border: 1px solid red;

  * {
    border: 3px solid green;
    margin: 1px;
  }
}


.error {
  color: red;
}
