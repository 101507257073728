.meeting-assist {
  z-index: 15;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 250px;

  .action-button {
    width: 150px;
    border: 1px solid grey;
  }

  textarea {
    resize: none;
  }

  .question-label {
    font-size: 1.2em;
  }
}
