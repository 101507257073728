.mbox-chat {
  padding: 5px;
  height: calc(100% - 100px);

  z-index: 4;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  vertical-align: bottom;
  box-sizing: border-box;
  position: relative;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom -100px left 90px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.messages {
  margin-top: 20px;
}

.top {
  background-color: chocolate;
  z-index: 10;
}

