.meetings-group-item {

  &:hover {
    background-color: #2f2f2f;
    cursor: pointer;
  }

  .main-block {
    gap: 15px;
    align-items: center;;
  }
  .meeting-title-block {
    font-size: 1.5rem;
    font-weight: 600;
    gap: 5px;
    .duration {
        color: #B5B5B5;
    }
  }

  .meet-item {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  .time-block {
    font-size: 1.5rem;
    font-weight: 600;
    color: #B5B5B5;
  }
}

.meetings-group-item-short {

  &:hover {
    background-color: #2f2f2f;
    cursor: pointer;
  }

  .main-block {
    gap: 15px;
    align-items: center;;
  }
  .meeting-title-block {
    font-size: 1.5rem;
    font-weight: 600;
    gap: 5px;
    .duration {
      color: #B5B5B5;
    }
  }

  .meet-item {
    display: flex;
    justify-content: space-between;
    gap: 5px;
  }

  .time-block {
    font-size: 1.5rem;
    font-weight: 600;
    color: #B5B5B5;
  }
}


.user-badge {
  margin: 3px;
  border: 1px solid black;
}

.inside-dropdown {
  background-color: #1e1e1f;
}
