*,
*::before,
*::after {
  font-family: inherit;
  box-sizing: border-box;
}

:root {
  --bg: #000000;
  --clr-1: #00c2ff;
  --clr-2: #33ff8c;
  --clr-3: #ffc640;
  --clr-4: #e54cff;

  --blur: 1rem;
  --fs: clamp(3rem, 8vw, 7rem);
  --ls: clamp(-1.75px, -0.25vw, -3.5px);

  scrollbar-color: #323232 transparent;
  scrollbar-width: thin;
}
