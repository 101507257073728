.aurora-wrapper {
  width: 100%;
  min-height: 100vh;
  background-color: var(--bg);
  color: #fff;
  display: grid;
  place-items: center;

  .content {
    text-align: center;
  }

  .title {
    font-size: var(--fs);
    font-weight: 800;
    letter-spacing: var(--ls);
    position: relative;
    overflow: hidden;
    background: var(--bg);
    margin: 0;
  }

  .google-btn {
    display: inline-block;
    background-color: #fff;
    color: #000;
    font-size: 1.5rem;
    font-weight: 800;
    padding: 1rem 2rem;
    border-radius: 1rem;
    text-decoration: none;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #333;
    }
  }

  .subtitle {
  }

  .aurora {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    mix-blend-mode: darken;
    pointer-events: none;
  }

  .aurora__item {
    overflow: hidden;
    position: absolute;
    width: 60vw;
    height: 60vw;
    background-color: var(--clr-1);
    border-radius: 37% 29% 27% 27% / 28% 25% 41% 37%;
    filter: blur(var(--blur));
    mix-blend-mode: overlay;
  }

  .aurora__item:nth-of-type(1) {
    top: -50%;
    animation: aurora-border 6s ease-in-out infinite,
    aurora-1 12s ease-in-out infinite alternate;
  }

  .aurora__item:nth-of-type(2) {
    background-color: var(--clr-3);
    right: 0;
    top: 0;
    animation: aurora-border 6s ease-in-out infinite,
    aurora-2 12s ease-in-out infinite alternate;
  }

  .aurora__item:nth-of-type(3) {
    background-color: var(--clr-2);
    left: 0;
    bottom: 0;
    animation: aurora-border 6s ease-in-out infinite,
    aurora-3 8s ease-in-out infinite alternate;
  }

  .aurora__item:nth-of-type(4) {
    background-color: var(--clr-4);
    right: 0;
    bottom: -50%;
    animation: aurora-border 6s ease-in-out infinite,
    aurora-4 24s ease-in-out infinite alternate;
  }

  @keyframes aurora-1 {
    0% {
      top: 0;
      right: 0;
    }

    50% {
      top: 100%;
      right: 75%;
    }

    75% {
      top: 100%;
      right: 25%;
    }

    100% {
      top: 0;
      right: 0;
    }
  }

  @keyframes aurora-2 {
    0% {
      top: -50%;
      left: 0;
    }

    60% {
      top: 100%;
      left: 75%;
    }

    85% {
      top: 100%;
      left: 25%;
    }

    100% {
      top: -50%;
      left: 0;
    }
  }

  @keyframes aurora-3 {
    0% {
      bottom: 0;
      left: 0;
    }

    40% {
      bottom: 100%;
      left: 75%;
    }

    65% {
      bottom: 40%;
      left: 50%;
    }

    100% {
      bottom: 0;
      left: 0;
    }
  }

  @keyframes aurora-4 {
    0% {
      bottom: -50%;
      right: 0;
    }

    50% {
      bottom: 0%;
      right: 40%;
    }

    90% {
      bottom: 50%;
      right: 25%;
    }

    100% {
      bottom: -50%;
      right: 0;
    }
  }

  @keyframes aurora-border {
    0% {
      border-radius: 37% 29% 27% 27% / 28% 25% 41% 37%;
    }

    25% {
      border-radius: 47% 29% 39% 49% / 61% 19% 66% 26%;
    }

    50% {
      border-radius: 57% 23% 47% 72% / 63% 17% 66% 33%;
    }

    75% {
      border-radius: 28% 49% 29% 100% / 93% 20% 64% 25%;
    }

    100% {
      border-radius: 37% 29% 27% 27% / 28% 25% 41% 37%;
    }
  }

}
