.cat-body {
  offset-path: path("M0,1 C99,1 10,5 10,10");

  animation: move 1000ms infinite alternate ease-in-out;
}

.leg {
  offset-path: path("M0,1 C99,1 10,5 10,10");
  animation: moveLeg 1000ms infinite alternate linear;
}

.lamp {
  animation: lightOnOf 1500ms infinite alternate ease-in-out;
}

.first-circle {
  animation: rotate 2s ease-in-out infinite;
  transform-origin: 56% 15%; /* Sets rotation point to center */
}

.second-circle {
  animation: reverseRotate 2s ease-in-out infinite;
  transform-origin: 84% 29%; /* Sets rotation point to center */
}

.third-circle {
  animation: rotate 2s ease-in-out infinite;
  transform-origin: 85% 55%; /* Sets rotation point to center */
}

.coffee {
  animation: coffee 2s ease-in-out infinite;
  transform-origin: 50% 50%; /* Sets rotation point to center */
}

@keyframes coffee {
  0% {
    fill: #9b9b9b;
  }
  50% {
    fill: #4f4f4f;
  }
  100% {
    fill: #000000;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
    fill: #f5f5f5;
  }
  50% {
    fill: #f5b104;
  }
  to {
    transform: rotate(360deg);
    fill: #5d5d5d;
  }
}

@keyframes reverseRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@keyframes lightOnOf {
  0% {
    fill: #f5f5f5;


  }
  50% {
    fill: #f5b104;
  }
  100% {
    fill: #f5b104;
    visibility: visible;
  }
}

@keyframes move {
  0% {
    offset-distance: 0;
  }
  100% {
    offset-distance: 10%;
  }
}

@keyframes moveLeg {
  0% {
    offset-distance: 0;
  }
  100% {
    offset-distance: 3%;
  }
}

