.icon-arrow {
  transform: rotate(90deg);
  border-radius: 50%;
  margin-left: 10px;
  //animation: move-arrow 2s infinite;
}

@keyframes move-arrow {
  0% {
    transform: rotate(85deg) scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: rotate(90deg) scale(1);
    box-shadow: 0 0 0 10px #543d13;
  }

  100% {
    transform: rotate(85deg) scale(0.95);
    box-shadow: 0 0 0 0 #f5b104;
  }
}
