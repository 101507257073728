
.summary-container {
  height: calc(100% - 65px);
  z-index: 999;
}

.transparent {
  background-color: transparent!important;
}
.mbox-summary {
  padding: 5px;
  height: calc(100% - 100px);

  overflow: scroll;
  justify-content: flex-start;
  box-sizing: border-box;
}

li::marker {
  color: white !important;
}

details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}
